export function useShowCart() {
  const { form } = useInterventionCartForm()
  const quote = useInterventionQuote(form.values)

  return computed(() => {
    return form.values?.device_model?.id
      || form.values?.customer?.id
      || quote.value.priceTotal > 0
  })
}
