import { withOptions } from '@directus/sdk'
import type { RefOrGetter } from '~/types/utils'
import { hydrateCustomer } from '~/utils/hydrate/customer'

interface Input {
  id: RefOrGetter<string>
}

export function useAsyncCustomerMessagesUnread() {
  const $directus = useDirectusSdk()
  let controller: AbortController | undefined
  onScopeDispose(() => {
    controller?.abort()
  })

  return useAsyncData(
    'inbox-unread-messages',
    async () => {
      controller?.abort()
      controller = new AbortController()

      try {
        const result = await $directus
          .request(
            withOptions(
              aggregate('customer_message', {
                aggregate: { count: '*' },
                query: {
                  filter: {
                    from: {
                      _eq: 'customer',
                    },
                    is_unread: {
                      _eq: true,
                    },
                  },
                },
              }),
              {
                signal: controller.signal,
              },
            ),
          )

        return Number((result?.[0]?.count as any)?.id) ?? 0
      }
      catch (error) {
        catchDirectusFetchError(error)
        return 0
      }
      finally {
        controller = undefined
      }
    },
  )
}

export function useAsyncCustomerMessages({
  id,
}: Input) {
  const $directus = useDirectusSdk()
  let controller: AbortController | undefined
  onScopeDispose(() => {
    controller?.abort()
  })

  return useAsyncData(
    `customer-messages:${toValue(id)}`,
    async () => {
      if (!toValue(id))
        return
      controller?.abort()
      controller = new AbortController()

      return await $directus
        .request(
          withOptions(
            readItem('customer', toValue(id), {
              fields: [
                'id',
                'first_name',
                'last_name',
                'email',
                'messages_status',
                // @todo: unread count  via directus aliases
                'messages_agent_unread',
                'email_bouncing',
                'email_bouncing_reason',
                'count(messages)' as unknown as 'messages_status',
                {
                  messages: [
                    '*',
                    {
                      user_created: ['id', 'avatar', 'first_name', 'last_name'],
                      user_updated: ['id', 'avatar', 'first_name', 'last_name'],
                      intervention: [
                        'id',
                        'status',
                      ],
                    },
                  ],
                  interventions: [
                    'id',
                    'status',
                  ],
                },
              ],
              deep: {
                messages: {
                  _sort: 'date_created',
                  _limit: -1,
                } as unknown as undefined,
                // interventions: {
                //   _sort: '-date_status_updated',
                //   _filter: {
                //     status: {
                //       _nin: ['archived', 'payed'],
                //     },
                //   },
                // } as unknown as undefined,
              },
            }),
            {
              signal: controller.signal,
            },
          ),
        )
        .then(hydrateCustomer)
        .catch((error) => {
          catchDirectusFetchError(error)
          return null
        })
        .finally(() => {
          controller = undefined
        })
    },
    {
      watch: [() => toValue(id)],
    },
  )
}
